/* tslint:disable max-line-length */
// Existing information messages
export const informationExists = () => 'A profile with this information already exists.';

// General success
export const saved = (name: string) => `"${name}" saved`;

// General errors
export const generalFailure = () => 'The requested operation could not be completed.';

export const insufficientPermissions = () => `
  You don't have permission to perform this action,
  please contact your administrator if you feel this is incorrect.
`;

export const ovationsAccess = () => `
  You do not have permission to access this application.
  Please contact an administrator if you believe this is in error.
`;

export const unsavedChanges = () => 'You have unsaved changes, are you sure you want to leave?';

export const noneExist = (noun: string) => `You haven't added any ${noun} yet.`;

export const updateFailure = (noun: string) => `The ${noun} could not be updated.`;

export const programConflict = (noun: string) =>
    `A ${noun} by that name already exists for this program. Please choose a different name.`;

export const duplicatePromotionGroupName = () =>
    'This group name matches an existing promotion group in this program. Enter a unique name to save.';

// Active Directory messages
export const adSearchFailure = () => 'The system appears to be unable to search users right now.';
export const noUsers = (term: string) => {
    return `Your search - ${term} - did not match any Active Directory users.`;
};

export const adAddFailure = () => 'The system appears to be unable to add users right now.';

export const userAddedHeading = () => 'New User Added';
export const userAddedBody = (name: string) => `
  ${name} has been added to O-vations.
  Finish the process by assigning this user permissions and roles.
`;

// Profile update messages
export const profileUpdateFailure = () => 'Profile could not be updated.';
export const profileUpdateSuccess = () => 'Profile updated.';

export const archiveFailure = (isArchived: boolean) => `
  Attempt to ${isArchived ? 'archive' : 'active'} user failed.
`;
export const archiveSuccess = (isArchived: boolean) => `
  User successfully ${isArchived ? 'archived' : 're-activated'}.
`;

// Client messages
export const clientExists = () => 'A client by that name already exists.';
export const clientAddedHeading = () => 'New Client Added';
export const clientAddedBody = (clientName: string) => `${clientName} has been added to O-vations.`;

export const clientSettingsUpdateFailure = () => 'Client settings could not be updated.';
export const clientSettingsUpdateSuccess = () => 'Client settings updated.';

// Data set messages
export const dataSetAddedHeading = () => 'New Data Set Added';
export const dataSetAddedBody = (dataSetName: string) => `${dataSetName} has been added to this program.`;

export const dataSetEdittedHeading = () => 'Data Set Editted';
export const dataSetEdittedBody = (dataSetName: string) => `${dataSetName} has been editted in this program.`;

export const dataSetFetchError = () => 'There was a problem getting the data sets for this program';

export const dataSetParseError = () => `
  This file doesn't contain what we need to make a data set.
  Check your file for proper column headers and at least one column with unique data.
`;
export const dataSetNoUniqueColumns = () => `
  We couldn't find any columns with unique values in the file you selected.
  Check your file and re-upload or select a new file.
`;

export const dataSetUniqueColumns = () => `
  To update this data set, a new file must contain the same Unique Identifier reference column.
`;

export const dataSetValidRewardAmounts = () => `
  To update this data set, a new file must meet all Reward Amount requirements.
`;

export const dataSetDuplicateBody = (dataSetName: string) => `
  "${dataSetName}" cannot be used because another data set already has this name.
  Please enter a different name.
`;

export const dataSetFileFormatError = () => `
  File format must be .xlsx or CSV.
`;

// Roles messages
export const userRoleUpdateFailure = () => 'Role could not be updated.';
export const userRoleDuplicateFailure = () => 'Role name already exists. Please choose different role name.';

export const userRoleUpdateSuccess = () => 'Role updated.';
export const userRoleAddSuccess = () => 'New Role added.';

// Programs messages
export const programFetchFailure = () => 'There was a problem getting the program data.';
export const programExists = () => 'A program by that name already exists.';
export const programUpdateFailure = () => 'Program could not be updated.';

export const programUpdateSuccess = () => 'Program updated.';
export const programAddSuccess = (programName: string) => `${programName} successfully added.`;

// Question messages
export const questionExists = (questionTitle: string) =>
    `A question titled "${questionTitle}" already exists for this program.`;
export const questionAddedHeading = () => 'New Question Added';
export const questionAddedBody = (questionTitle: string) => `"${questionTitle}" has been added to this program.`;
export const questionUpdatedHeading = () => 'Question updated';
export const questionUpdatedBody = (questionTitle: string) => `"${questionTitle}" has been successfully updated.`;
export const questionUpdateFailure = () => 'Question could not be updated.';

// Document messages
export const documentExists = (documentTitle: string) =>
    `A document titled "${documentTitle}" already exists for this program.`;
export const documentAdded = (documentTitle: string) => `"${documentTitle}" added`;
export const documentAddFailure = () => 'Document could not be saved.';
export const documentCopyLinkHeading = () => 'Document link is copied';
export const documentCopyLinkBody = (documentTitle: string) =>
    `"${documentTitle}" link has been copied to the clipboard`;

// Search messages
export const noResults = () => 'There are no records that match your search.';

export const searchHelpPrompt = () => 'Use quotation marks to search multiple words in a field. E.g. "Jon Doe"';

export const searchHelpPromptForAddresses = () => 'Enter a street address to begin searching';

export const searchHelpPromptForClaim = () => 'Enter a claim number and hit Enter or click the search icon';

// Call Type and Disposition messages
export const callTypeExists = (callTypeTitle: string) =>
    `A call type titled "${callTypeTitle}" already exists for this program.`;
export const dispositionExists = (dispositionTitle: string) =>
    `A disposition titled "${dispositionTitle}" already exists for this program.`;

// Call center messages
export const callDetailsSaved = () => 'Call details have been saved';

// FAQ messages
export const faqTitlePlaceholder = () => `
  e.g. Is this a credit card?
`;

export const faqResponseTextPlaceholder = () =>
    `e.g. No. This is a prepaid Reward Card debit card that can be used everywhere Reward Card debit cards are accepted worldwide. The Reward Card is preloaded with your reward funds, and can only be used up to the balance on your Reward Card. Remember to keep track of your balance or go online to check you balance.`;

export const faqSectionListEmptyPlaceholder = () => `
  No sections have been added yet. Click "ADD" to name and create a new section.
`;

export const promotionEligibilityGroupListEmptyPlaceholder = () =>
    `No groups have been added yet. Click add to start defining an eligibility group.`;
export const promotionEligibilityGroupDetailEmptyPlaceholder = () =>
    `No fields have been added to this eligibility group yet. Click Add Profile Field to start adding fields.`;

// Batch messages
export const cancelledBatch = (batchName: string) => `Succesfully cancelled batch: ${batchName}`;

// Report messages
export const powerBiIdNotFound = (id: string) => `Power BI Report ID: ${id} Not Found`;

// Claim Import
export const claimImportFileExistsHeading = () => `File Already Exists`;
export const claimImportExceedsDataLimitHeading = () => `Row Limit Exceeded`;
export const claimImportInvalidFileFormatHeading = () => `Invalid File Format`;

export const claimImportFileAdded = (title: string) => `"${title}" added`;
export const claimImportFileExists = () => `File already uploaded or needs to be renamed.`;
export const claimImportExceedsDataLimit = () => `Your file exceeds 1000 records.`;
export const claimImportInvalidFileType = () => `File type must be CSV using the approved import template.`;
